<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#E3F2FD">
        <v-card-text
          class="w-block__body2"
          :class="{
            'pa-3': $voicer.isMobile === false,
            'pa-2': $voicer.isMobile === true,
            Subtitle2: $voicer.isMobile,
            Subtitle2: $voicer.isMobile === false,
          }"
        >
          <strong>
            Bienvenue sur le site de votre nouveau podcast ! Radio Papilles
            c’est le média 100% dédié aux équipes de Sodexo et Sogeres Santé
            Médico-Social
          </strong>
          <br />
          Vous souhaitez nous envoyer un message, partager une actu ou jouer à
          un de nos jeux !
          <br />
          Ecrivez à
          <a href="mailto:contact@radiopapilles.fr">contact@radiopapilles.fr</a>
        </v-card-text>
      </v-card>
    </v-container>
    <exc-dialog />
  </div>
</template>
<script>
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
  cursor: pointer;
}
</style>