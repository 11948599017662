const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */

    siteId: '63ff15641377613737bddb0e',
    WFeedBack: {
      //   ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: 'Enregistrez votre talent',
        name: 'Anecdotes talents',
        type: 'audio',
        maxDuration: 120,
        headCaption: ` Chanteur, musicien, dj, beatboxer, compositeur, imitateur, orateur, en solo ou en équipe, quel que soit votre talent, montrez-le nous dès maintenant ! Notre jury Radio Papilles sélectionnera ses talents préférés pour une émission exceptionnelle ! Alors à vos micros !`,
        subCaption: `Je suis informé que Sodexo traite les données recueillies pour permettre votre participation à un jeu concours. Pour en savoir plus,
      reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message à bien été envoyé.`,
        pseudonym: {
          label: 'Nom Prénom',
          placeholder: 'Dupont Martin',
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'martin@hotmail.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: "Si je suis sélectionné(e), j’accepte que mon message soit diffusé pendant l’émission",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    enableExperimentalBlocks: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    metadatas: {
      name: `Radio Papilles`,
      colorPrimary: '#283897',
      colorAccent: '#228085',
      supportEmailAddress: 'contact@radiopapilles.fr',
    },
    analytics: {
      matomo: '36',
    },
    useShell: [
      'bb-default',
      {
        mode: 'list',
      },
    ],

    nuxt: {
      robots: {
        UserAgent: '*',
        Disallow: '',
      },
      head: {
        title:
          'RADIO PAPILLES | Le podcast 100% dédié aux équipes de Sodexo et Sogeres Santé Médico-Social',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Le podcast interne au coeur des cuisines de Sodexo et Sogeres Santé Médico Social avec de l’info, des rencontres et des conseils d’experts !`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index,follow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content:
              'RADIO PAPILLES | Le podcast 100% dédié aux équipes de Sodexo et Sogeres Santé Médico-Social',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content:
              'Le podcast interne au coeur des cuisines de Sodexo et Sogeres Santé Médico Social avec de l’info, des rencontres et des conseils d’experts !',
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
