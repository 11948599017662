<template>
  <div class="w-block__footer">
    <!-- footer blockk -->
    <img
      :src="require('~/assets/images/sodexo_footer.png')"
      height="80"
      alt="sodexo"
      class=""
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
